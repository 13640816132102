/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        /****************
        **  Style gradient buttons
        *****************/
//         $( ".btn-gradient" ).wrapInner( "<span></span>");

        $('.recent-posts-extended .rpwe-block ul li').each(function( index ) {
          var postLink = $(this).find('a').attr('href');
          $(this).append('<a href="'+postLink+'" class="btn">read how</a>');
        });

        $(".gform_wrapper .gfield_required").html("(required)");
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'single_post': {
      init: function() {
        // JavaScript to be fired on the home page
        $('.nav-primary .nav .nav-item-19').addClass('active');
      }
    },
    'single_story': {
      init: function() {
        // JavaScript to be fired on the home page
        $('.nav-primary .nav .nav-item-22').addClass('active');
      }
    },
    'post_type_archive_story': {
      init: function() {
        // JavaScript to be fired on the home page
        $('.nav-primary .nav .nav-item-22').addClass('active');
      }
    },
    'page_id_95': {
      init: function() {
        // JavaScript to be fired on the home page
        $('.nav-primary .nav .nav-item-22').addClass('active');
      }
    },
    'single_conversation': {
      init: function() {
        // JavaScript to be fired on the home page
        $('.nav-primary .nav .nav-item-20').addClass('active');
      }
    },
    'post_type_archive_conversation': {
      init: function() {
        // JavaScript to be fired on the home page
        $('.nav-primary .nav .nav-item-20').addClass('active');
      }
    },
    'page_id_93': {
      init: function() {
        // JavaScript to be fired on the home page
        $('.nav-primary .nav .nav-item-20').addClass('active');
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
